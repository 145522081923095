<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Email To:
      <v-chip small label outlined class="ml-2 mr-2" style="flex:none">{{ toEmail }}</v-chip>
      Subject:
      <v-chip small label outlined class="ml-2 mr-2" style="flex:none">{{ subject || 'New Notification' }}</v-chip>
    </v-expansion-panel-header>
    <v-expansion-panel-content color="grey lighten-5">
      <v-card-text>
        <v-row>
          <v-col :cols="12" md="6">
            <v-text-field v-model="fromEmail" label="Send email from" hint="If left blank, will use the default: report-email@covenant.edu" persistent-hint outlined dense>
              <template v-slot:append-outer>
                <v-menu>
                  <template v-slot:activator="{ on: menuOn }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltipOn }">
                        <v-btn v-on="{ ...menuOn, ...tooltipOn }" icon style="margin-top:-8px">
                          <v-icon small>fal fa-brackets-curly</v-icon>
                        </v-btn>
                      </template>
                      <span>Use merge field for "from" email (if a non-Covenant email is selected or entered, then that email will be used as the reply-to instead of the from email)</span>
                    </v-tooltip>
                  </template>
                  <v-list dense>
                    <v-subheader>System Fields</v-subheader>
                    <v-list-item @click="addField('fromEmail', 'user::email')">
                      <v-list-item-title>Currently logged-in User</v-list-item-title>
                    </v-list-item>
                    <v-subheader>Form Fields (Email type only)</v-subheader>
                    <v-list-item v-for="(text, index) in emailFields" :key="'merge-' + index" @click="addField('fromEmail', text)">
                      <v-list-item-title>{{ text }}</v-list-item-title>
                    </v-list-item>
                    <v-subheader v-if="emailFields.length === 0">**No email fields in the form**</v-subheader>
                  </v-list>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
          <v-col :cols="12" md="6">
            <v-text-field v-model="toEmail" label="Send email to" outlined dense>
              <template v-slot:append-outer>
                <v-menu>
                  <template v-slot:activator="{ on: menuOn }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltipOn }">
                        <v-btn v-on="{ ...menuOn, ...tooltipOn }" icon style="margin-top:-8px">
                          <v-icon small>fal fa-brackets-curly</v-icon>
                        </v-btn>
                      </template>
                      <span>Use merge field for to email</span>
                    </v-tooltip>
                  </template>
                  <v-list dense>
                    <v-subheader>System Fields</v-subheader>
                    <v-list-item @click="addField('toEmail', 'user::email')">
                      <v-list-item-title>Currently logged-in User</v-list-item-title>
                    </v-list-item>
                    <v-subheader>Form Fields (Email type only)</v-subheader>
                    <v-list-item v-for="(text, index) in emailFields" :key="'merge-' + index" @click="addField('toEmail', text)">
                      <v-list-item-title>{{ text }}</v-list-item-title>
                    </v-list-item>
                    <v-subheader v-if="emailFields.length === 0">**No email fields in the form**</v-subheader>
                  </v-list>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
          <v-col :cols="12" class="pb-0">
            <v-text-field v-model="subject" label="Subject" outlined dense>
              <template v-slot:append-outer>
                <v-menu>
                  <template v-slot:activator="{ on: menuOn }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltipOn }">
                        <v-btn v-on="{ ...menuOn, ...tooltipOn }" icon style="margin-top:-8px">
                          <v-icon small>fal fa-brackets-curly</v-icon>
                        </v-btn>
                      </template>
                      <span>Add merge field to subject</span>
                    </v-tooltip>
                  </template>
                  <v-list dense>
                    <v-subheader>System Fields</v-subheader>
                    <v-list-item @click="addField('subject', '[Unique ID]')">
                      <v-list-item-title>[Unique ID]</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="addField('subject', 'Submitted Date')">
                      <v-list-item-title>Submitted Date</v-list-item-title>
                    </v-list-item>
                    <v-subheader>Form Fields</v-subheader>
                    <v-list-item v-for="(text, index) in mergeFields" :key="'merge-' + index" @click="addField('subject', text)">
                      <v-list-item-title>{{ text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
          <v-col :cols="12" class="pt-0">
            <label>Email Body (leave blank and check the box below to just include the form data as the notification body)</label>
            <html-block v-model="body" :mergeFields="mergeFields"></html-block>
          </v-col>
          <v-col :cols="12" class="pb-0">
            <v-checkbox v-model="includeData" label="Include form data after body?" dense hide-details></v-checkbox>
          </v-col>
          <v-col :cols="12" class="pt-0">
            <v-switch v-model="isConditional" :label="'Conditional: ' + (isConditional ? 'True (send notification only when data matches)' : 'False (send notification on every submission)')" dense hide-details></v-switch>
          </v-col>
        </v-row>
        <template v-if="isConditional">
          <access-block-criteria-row v-for="(obj, condIndex) in conditions" :key="'condition-' + condIndex" v-model="conditions[condIndex]" :formFields="formFields" dense @remove="conditions.splice(index, 1)"></access-block-criteria-row>
          <v-btn @click="addCondition">Add Condition</v-btn>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="$emit('remove')">Remove Notification</v-btn>
      </v-card-actions>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
export default {
  components: {
    HtmlBlock: () => import('../HTMLEditor'),
    AccessBlockCriteriaRow: () => import('./AccessBlockCriteriaRow')
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {
        return {
          toEmail: '',
          subject: '',
          fromEmail: '',
          body: '',
          includeData: false,
          isConditional: false,
          conditions: []
        }
      }
    },
    formFields: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup (props, { root, emit }) {
    const toEmail = ref(props.value.toEmail || '')
    const subject = ref(props.value.subject || '')
    function addField (where, val) {
      switch (where) {
        case 'subject':
          if (subject.value !== '') subject.value += ' '
          subject.value += '{{' + val + '}}'
          return
        case 'toEmail':
          toEmail.value = '{{' + val + '}}'
          return
        case 'fromEmail':
          fromEmail.value = '{{' + val + '}}'
      }
    }
    const fromEmail = ref(props.value.fromEmail || '')
    const body = ref(props.value.body || '')
    const includeData = ref(props.value.includeData || false)
    const isConditional = ref(props.value.isConditional || false)
    const conditions = ref(props.value.conditions || [])

    function addCondition () {
      conditions.value.push({ field: '', value: '' })
    }
    function updateCondition (obj, index) {
      let temp = { ...conditions.value[index] }
      for (let field in obj) temp[field] = obj[field]
      conditions.value.splice(index, 1, temp)
    }

    watch(() => props.value, () => {
      toEmail.value = props.value.toEmail
      subject.value = props.value.subject
      fromEmail.value = props.value.fromEmail
      body.value = props.value.body
      includeData.value = props.value.includeData
      isConditional.value = props.value.isConditional
      conditions.value = props.value.conditions
    })

    watch([toEmail, subject, body, includeData, fromEmail, isConditional, conditions], () => {
      let doUpdate = false
      if (toEmail.value !== props.value.toEmail) doUpdate = true
      if (subject.value !== props.value.subject) doUpdate = true
      if (body.value !== props.value.body) doUpdate = true
      if (includeData.value !== props.value.includeData) doUpdate = true
      if (fromEmail.value !== props.value.fromEmail) doUpdate = true
      if (isConditional.value !== props.value.isConditional) doUpdate = true
      if (JSON.stringify(conditions.value) !== JSON.stringify(props.value.conditions)) doUpdate = true

      if (doUpdate) {
        emit('input', { toEmail: toEmail.value, subject: subject.value, fromEmail: fromEmail.value, body: body.value, includeData: includeData.value, isConditional: isConditional.value, conditions: conditions.value })
      }
    })

    const mergeFields = computed(() => props.formFields.map(({ text }) => text))
    const emailFields = computed(() => props.formFields.filter(({ input }) => input === 'email').map(({ text }) => text))

    return {
      toEmail,
      subject,
      addField,
      fromEmail,
      body,
      includeData,
      isConditional,
      conditions,
      addCondition,
      updateCondition,
      mergeFields,
      emailFields
    }
  }
}
</script>
